/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We use the following units of measurement in our API:"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "center"
  }, "Code"), React.createElement(_components.th, {
    align: "center"
  }, "Unit of Measurement"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ACR"), React.createElement(_components.td, {
    align: "center"
  }, "Acre")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "AMH"), React.createElement(_components.td, {
    align: "center"
  }, "Ampere-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "AMP"), React.createElement(_components.td, {
    align: "center"
  }, "Ampere")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ANN"), React.createElement(_components.td, {
    align: "center"
  }, "Year")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "APZ"), React.createElement(_components.td, {
    align: "center"
  }, "Troy or apothecaries’ ounce. 12 troy ounces = 1 troy pound.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ARE"), React.createElement(_components.td, {
    align: "center"
  }, "Are")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ASM"), React.createElement(_components.td, {
    align: "center"
  }, "Alcoholic strength mass")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ASV"), React.createElement(_components.td, {
    align: "center"
  }, "Alcoholic strength by volume")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ATM"), React.createElement(_components.td, {
    align: "center"
  }, "Standard atmosphere")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ATT"), React.createElement(_components.td, {
    align: "center"
  }, "Technical atmosphere")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BAR"), React.createElement(_components.td, {
    align: "center"
  }, "Bar")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BFT"), React.createElement(_components.td, {
    align: "center"
  }, "Board foot")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BHP"), React.createElement(_components.td, {
    align: "center"
  }, "Brake horsepower")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BHX"), React.createElement(_components.td, {
    align: "center"
  }, "Hundred boxes")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BIL"), React.createElement(_components.td, {
    align: "center"
  }, "Billion, E.U. ", React.createElement(_components.strong, null, "Note"), ": This is equivalent to trillion in the U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BLD"), React.createElement(_components.td, {
    align: "center"
  }, "Dry barrel")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BLL"), React.createElement(_components.td, {
    align: "center"
  }, "Barrel")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BQL"), React.createElement(_components.td, {
    align: "center"
  }, "Becquerel")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BTU"), React.createElement(_components.td, {
    align: "center"
  }, "British thermal unit")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BUA"), React.createElement(_components.td, {
    align: "center"
  }, "Bushel, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BUI"), React.createElement(_components.td, {
    align: "center"
  }, "Bushel, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "BX"), React.createElement(_components.td, {
    align: "center"
  }, "Box")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CCT"), React.createElement(_components.td, {
    align: "center"
  }, "Carrying capacity in metric tonnes")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CDL"), React.createElement(_components.td, {
    align: "center"
  }, "Candela")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CEL"), React.createElement(_components.td, {
    align: "center"
  }, "Degrees Celsius")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CEN"), React.createElement(_components.td, {
    align: "center"
  }, "Hundred")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CGM"), React.createElement(_components.td, {
    align: "center"
  }, "Centigram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CKG"), React.createElement(_components.td, {
    align: "center"
  }, "Coulomb per kilogram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CLF"), React.createElement(_components.td, {
    align: "center"
  }, "Hundred leaves")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CLT"), React.createElement(_components.td, {
    align: "center"
  }, "Centiliter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CMK"), React.createElement(_components.td, {
    align: "center"
  }, "Square centimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CMT"), React.createElement(_components.td, {
    align: "center"
  }, "Centimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CNP"), React.createElement(_components.td, {
    align: "center"
  }, "Hundred packs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CNT"), React.createElement(_components.td, {
    align: "center"
  }, "Cental U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "COU"), React.createElement(_components.td, {
    align: "center"
  }, "Coulomb")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CS"), React.createElement(_components.td, {
    align: "center"
  }, "Case")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CTM"), React.createElement(_components.td, {
    align: "center"
  }, "Metric carat")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CUR"), React.createElement(_components.td, {
    align: "center"
  }, "Curie")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "CWA"), React.createElement(_components.td, {
    align: "center"
  }, "Hundredweight U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DAA"), React.createElement(_components.td, {
    align: "center"
  }, "Decare")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DAD"), React.createElement(_components.td, {
    align: "center"
  }, "Ten days")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DAY"), React.createElement(_components.td, {
    align: "center"
  }, "Day")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DEC"), React.createElement(_components.td, {
    align: "center"
  }, "Decade")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DLT"), React.createElement(_components.td, {
    align: "center"
  }, "Deciliter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DMK"), React.createElement(_components.td, {
    align: "center"
  }, "Square decimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DMQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic decimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DMT"), React.createElement(_components.td, {
    align: "center"
  }, "Decimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DPC"), React.createElement(_components.td, {
    align: "center"
  }, "Dozen pieces")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DPT"), React.createElement(_components.td, {
    align: "center"
  }, "Displacement tonnage")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DRA"), React.createElement(_components.td, {
    align: "center"
  }, "Dram, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DRI"), React.createElement(_components.td, {
    align: "center"
  }, "Dram, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DRL"), React.createElement(_components.td, {
    align: "center"
  }, "Dozen rolls")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DRM"), React.createElement(_components.td, {
    align: "center"
  }, "Drachm")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DTH"), React.createElement(_components.td, {
    align: "center"
  }, "Hectokilogram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DTN"), React.createElement(_components.td, {
    align: "center"
  }, "Centner or quintal, metric")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DWT"), React.createElement(_components.td, {
    align: "center"
  }, "Pennyweight")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DZN"), React.createElement(_components.td, {
    align: "center"
  }, "Dozen")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DZP"), React.createElement(_components.td, {
    align: "center"
  }, "Dozen packs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "DZR"), React.createElement(_components.td, {
    align: "center"
  }, "Dozen pairs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "EA"), React.createElement(_components.td, {
    align: "center"
  }, "Each")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "EAC"), React.createElement(_components.td, {
    align: "center"
  }, "Each")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "FAH"), React.createElement(_components.td, {
    align: "center"
  }, "Degrees Fahrenheit")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "FAR"), React.createElement(_components.td, {
    align: "center"
  }, "Farad")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "FOT"), React.createElement(_components.td, {
    align: "center"
  }, "Foot")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "FTK"), React.createElement(_components.td, {
    align: "center"
  }, "Square foot")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "FTQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic foot")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GBQ"), React.createElement(_components.td, {
    align: "center"
  }, "Gigabecquerel")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GFI"), React.createElement(_components.td, {
    align: "center"
  }, "Gram of fissile isotopes")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GGR"), React.createElement(_components.td, {
    align: "center"
  }, "Great gross")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GIA"), React.createElement(_components.td, {
    align: "center"
  }, "Gill, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GII"), React.createElement(_components.td, {
    align: "center"
  }, "Gill, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GLD"), React.createElement(_components.td, {
    align: "center"
  }, "Dry gallon, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GLI"), React.createElement(_components.td, {
    align: "center"
  }, "Gallon, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GLL"), React.createElement(_components.td, {
    align: "center"
  }, "Liquid gallon, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GRM"), React.createElement(_components.td, {
    align: "center"
  }, "Gram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GRN"), React.createElement(_components.td, {
    align: "center"
  }, "Grain")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GRO"), React.createElement(_components.td, {
    align: "center"
  }, "Gross")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GRT"), React.createElement(_components.td, {
    align: "center"
  }, "Gross register tonnage")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "GWH"), React.createElement(_components.td, {
    align: "center"
  }, "Gigawatt-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HAR"), React.createElement(_components.td, {
    align: "center"
  }, "Hectare")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HBA"), React.createElement(_components.td, {
    align: "center"
  }, "Hectobar")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HGM"), React.createElement(_components.td, {
    align: "center"
  }, "Hectogram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HIU"), React.createElement(_components.td, {
    align: "center"
  }, "Hundred international units")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HLT"), React.createElement(_components.td, {
    align: "center"
  }, "Hectoliter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HMQ"), React.createElement(_components.td, {
    align: "center"
  }, "Million cubic meters")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HMT"), React.createElement(_components.td, {
    align: "center"
  }, "Hectometer")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HPA"), React.createElement(_components.td, {
    align: "center"
  }, "Hectoliter of pure alcohol")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HTZ"), React.createElement(_components.td, {
    align: "center"
  }, "Hertz")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "HUR"), React.createElement(_components.td, {
    align: "center"
  }, "Hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "INH"), React.createElement(_components.td, {
    align: "center"
  }, "Inch")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "INK"), React.createElement(_components.td, {
    align: "center"
  }, "Square inch")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "INQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic inch")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ITM"), React.createElement(_components.td, {
    align: "center"
  }, "Item")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "JOU"), React.createElement(_components.td, {
    align: "center"
  }, "Joule")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KBA"), React.createElement(_components.td, {
    align: "center"
  }, "Kilobar")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KEL"), React.createElement(_components.td, {
    align: "center"
  }, "Kelvin")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KGM"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KGS"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram per second")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KHZ"), React.createElement(_components.td, {
    align: "center"
  }, "Kilohertz")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KJO"), React.createElement(_components.td, {
    align: "center"
  }, "Kilojoule")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KMH"), React.createElement(_components.td, {
    align: "center"
  }, "Kilometer per hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KMK"), React.createElement(_components.td, {
    align: "center"
  }, "Square kilometer")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KMQ"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram per cubic meter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KMT"), React.createElement(_components.td, {
    align: "center"
  }, "Kilometer")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KNI"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of nitrogen")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KNS"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of named substance")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KNT"), React.createElement(_components.td, {
    align: "center"
  }, "Knot")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KPA"), React.createElement(_components.td, {
    align: "center"
  }, "Kilopascal")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KPH"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of caustic potash,  kilogram of potassium hydroxide")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KPO"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of potassium oxide")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KPP"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of phosphoric anhydride, kilogram of phosphoric pentoxide")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KSD"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of substance, 90% dry")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KSH"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of caustic soda")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KTN"), React.createElement(_components.td, {
    align: "center"
  }, "Kilotonne")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KUR"), React.createElement(_components.td, {
    align: "center"
  }, "Kilogram of uranium")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KVA"), React.createElement(_components.td, {
    align: "center"
  }, "Kilovolt-ampere")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KVR"), React.createElement(_components.td, {
    align: "center"
  }, "Kilovar")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KVT"), React.createElement(_components.td, {
    align: "center"
  }, "Kilovolt")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KWH"), React.createElement(_components.td, {
    align: "center"
  }, "Kilowatt-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "KWT"), React.createElement(_components.td, {
    align: "center"
  }, "Kilowatt")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LBR"), React.createElement(_components.td, {
    align: "center"
  }, "Pound, U.K., U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LBS"), React.createElement(_components.td, {
    align: "center"
  }, "Pounds")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LBT"), React.createElement(_components.td, {
    align: "center"
  }, "Troy pound")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LEF"), React.createElement(_components.td, {
    align: "center"
  }, "Leaf")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LPA"), React.createElement(_components.td, {
    align: "center"
  }, "Liter of pure alcohol")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LTN"), React.createElement(_components.td, {
    align: "center"
  }, "Long ton, imperial")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LTR"), React.createElement(_components.td, {
    align: "center"
  }, "Liter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LUM"), React.createElement(_components.td, {
    align: "center"
  }, "Lumen")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "LUX"), React.createElement(_components.td, {
    align: "center"
  }, "Lux")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MAL"), React.createElement(_components.td, {
    align: "center"
  }, "Megaliter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MAM"), React.createElement(_components.td, {
    align: "center"
  }, "Megameter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MAW"), React.createElement(_components.td, {
    align: "center"
  }, "Megawatt")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MBE"), React.createElement(_components.td, {
    align: "center"
  }, "Thousand standard brick equivalent")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MBF"), React.createElement(_components.td, {
    align: "center"
  }, "Thousand board-feet")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MBR"), React.createElement(_components.td, {
    align: "center"
  }, "Millibar")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MCU"), React.createElement(_components.td, {
    align: "center"
  }, "Millicurie")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MGM"), React.createElement(_components.td, {
    align: "center"
  }, "Milligram")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MHZ"), React.createElement(_components.td, {
    align: "center"
  }, "Megahertz")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MIK"), React.createElement(_components.td, {
    align: "center"
  }, "Square mile")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MIL"), React.createElement(_components.td, {
    align: "center"
  }, "Thousand")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MIN"), React.createElement(_components.td, {
    align: "center"
  }, "Minute")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MIO"), React.createElement(_components.td, {
    align: "center"
  }, "Million")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MIU"), React.createElement(_components.td, {
    align: "center"
  }, "Million international units")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MLD"), React.createElement(_components.td, {
    align: "center"
  }, "Billion, U.S., one thousand million)")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MLT"), React.createElement(_components.td, {
    align: "center"
  }, "Milliliter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MMK"), React.createElement(_components.td, {
    align: "center"
  }, "Square millimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MMQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic millimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MMT"), React.createElement(_components.td, {
    align: "center"
  }, "Millimeter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MON"), React.createElement(_components.td, {
    align: "center"
  }, "Month")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MQH"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic meter per hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MQS"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic meter per second")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MSK"), React.createElement(_components.td, {
    align: "center"
  }, "Meter per second squared")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MTK"), React.createElement(_components.td, {
    align: "center"
  }, "Square meter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MTQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic meter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MTR"), React.createElement(_components.td, {
    align: "center"
  }, "Meter")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MTS"), React.createElement(_components.td, {
    align: "center"
  }, "Meter per second")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MVA"), React.createElement(_components.td, {
    align: "center"
  }, "Megavolt-ampere")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "MWH"), React.createElement(_components.td, {
    align: "center"
  }, "Megawatt-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NAR"), React.createElement(_components.td, {
    align: "center"
  }, "Number of articles")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NBB"), React.createElement(_components.td, {
    align: "center"
  }, "Number of bobbins")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NCL"), React.createElement(_components.td, {
    align: "center"
  }, "Number of cells")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NEW"), React.createElement(_components.td, {
    align: "center"
  }, "Newton")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NIU"), React.createElement(_components.td, {
    align: "center"
  }, "Number of international units")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NMB"), React.createElement(_components.td, {
    align: "center"
  }, "Number")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NMI"), React.createElement(_components.td, {
    align: "center"
  }, "Nautical mile")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NMP"), React.createElement(_components.td, {
    align: "center"
  }, "Number of packs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NMR"), React.createElement(_components.td, {
    align: "center"
  }, "Number of pairs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NPL"), React.createElement(_components.td, {
    align: "center"
  }, "Number of parcels")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NPT"), React.createElement(_components.td, {
    align: "center"
  }, "Number of parts")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NRL"), React.createElement(_components.td, {
    align: "center"
  }, "Number of rolls")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "NTT"), React.createElement(_components.td, {
    align: "center"
  }, "Net register tonnage")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "OHM"), React.createElement(_components.td, {
    align: "center"
  }, "Ohm")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ONZ"), React.createElement(_components.td, {
    align: "center"
  }, "Imperial ounce, sometimes known as an avoirdupois ounce. 16 avoirdupois ounces = 1 pound.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "OZA"), React.createElement(_components.td, {
    align: "center"
  }, "Fluid ounce U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "OZI"), React.createElement(_components.td, {
    align: "center"
  }, "Fluid ounce U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PAL"), React.createElement(_components.td, {
    align: "center"
  }, "Pascal")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PCB"), React.createElement(_components.td, {
    align: "center"
  }, "Piece")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PCE"), React.createElement(_components.td, {
    align: "center"
  }, "Piece")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PGL"), React.createElement(_components.td, {
    align: "center"
  }, "Proof gallon")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PK"), React.createElement(_components.td, {
    align: "center"
  }, "Package")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PTD"), React.createElement(_components.td, {
    align: "center"
  }, "Dry pint, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PTI"), React.createElement(_components.td, {
    align: "center"
  }, "Pint, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "PTL"), React.createElement(_components.td, {
    align: "center"
  }, "Pint, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "QAN"), React.createElement(_components.td, {
    align: "center"
  }, "Quarter of a year")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "QTD"), React.createElement(_components.td, {
    align: "center"
  }, "Dry quart, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "QTI"), React.createElement(_components.td, {
    align: "center"
  }, "Quart, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "QTL"), React.createElement(_components.td, {
    align: "center"
  }, "Liquid quart, U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "QTR"), React.createElement(_components.td, {
    align: "center"
  }, "Quarter, U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "RPM"), React.createElement(_components.td, {
    align: "center"
  }, "Revolution per minute")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "RPS"), React.createElement(_components.td, {
    align: "center"
  }, "Revolution per second")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SAN"), React.createElement(_components.td, {
    align: "center"
  }, "Half year")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SCO"), React.createElement(_components.td, {
    align: "center"
  }, "Score")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SCR"), React.createElement(_components.td, {
    align: "center"
  }, "Scruple")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SEC"), React.createElement(_components.td, {
    align: "center"
  }, "Second")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SET"), React.createElement(_components.td, {
    align: "center"
  }, "Set")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SHT"), React.createElement(_components.td, {
    align: "center"
  }, "Shipping ton")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SIE"), React.createElement(_components.td, {
    align: "center"
  }, "Siemens")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SMI"), React.createElement(_components.td, {
    align: "center"
  }, "Statute mile")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "SST"), React.createElement(_components.td, {
    align: "center"
  }, "Short Standard")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "ST"), React.createElement(_components.td, {
    align: "center"
  }, "Unknown unit of measure")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "STI"), React.createElement(_components.td, {
    align: "center"
  }, "Stone U.K.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "STN"), React.createElement(_components.td, {
    align: "center"
  }, "Short ton")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TAH"), React.createElement(_components.td, {
    align: "center"
  }, "Thousand ampere-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TNE"), React.createElement(_components.td, {
    align: "center"
  }, "Metric tonne")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TPR"), React.createElement(_components.td, {
    align: "center"
  }, "Ten pairs")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TQD"), React.createElement(_components.td, {
    align: "center"
  }, "Thousand cubic meters per day")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TRL"), React.createElement(_components.td, {
    align: "center"
  }, "Trillion, E.U.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TSD"), React.createElement(_components.td, {
    align: "center"
  }, "Tonne of substance 90% dry")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "TSH"), React.createElement(_components.td, {
    align: "center"
  }, "Ton of steam per hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "VLT"), React.createElement(_components.td, {
    align: "center"
  }, "Volt")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WCD"), React.createElement(_components.td, {
    align: "center"
  }, "Cord")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WEB"), React.createElement(_components.td, {
    align: "center"
  }, "Weber")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WEE"), React.createElement(_components.td, {
    align: "center"
  }, "Week")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WHR"), React.createElement(_components.td, {
    align: "center"
  }, "Watt-hour")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WSD"), React.createElement(_components.td, {
    align: "center"
  }, "Standard")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "WTT"), React.createElement(_components.td, {
    align: "center"
  }, "Watt")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "YDK"), React.createElement(_components.td, {
    align: "center"
  }, "Square yard")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "YDQ"), React.createElement(_components.td, {
    align: "center"
  }, "Cubic yard")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "center"
  }, "YRD"), React.createElement(_components.td, {
    align: "center"
  }, "Yard")))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
